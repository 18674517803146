/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';

class LoaderComponent extends React.Component {

  render() {
      const { text } = this.props;
    return (
        <div>{typeof(text) !== 'undefined' ? text : "Laddar..."}</div>
    );
  }
}

export default LoaderComponent;
